import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { CommunityEventSign } from './model.type';

const base_path = '/community_event_sign';


const ListByCommunityEventID = (q: PageQuery, community_event_id = '') => {
    return http.get(`${base_path}/list_by_community_event_id?community_event_id=${community_event_id}&${http.pageToUrlParams(q)}`)
}

const ExportByCommunityEventID = (community_event_id = '') => {
    // window.open(`${import.meta.env.VITE_APP_BASE_API || ''}${base_path}/export_by_community_event_id?community_event_id=${community_event_id}`, '_blank')
    return http.get(`${base_path}/export_by_community_event_id?community_event_id=${community_event_id}`)
}


// 取消核销
const CancelVerify = (community_event_sign_id: string, reason: string = "管理员操作") => {
    return http.post(`${base_path}/cancel_verify?community_event_sign_id=${community_event_sign_id}&reason=${reason}`,{})
}

// 核销
const Verify = (community_event_sign_id: string, reason: string = '管理员核销') => {
    return http.post(`${base_path}/verify?community_event_sign_id=${community_event_sign_id}&reason=${reason}`,{})
}

export default {
    ListByCommunityEventID,
    ExportByCommunityEventID,
    CancelVerify,
    Verify,
};
